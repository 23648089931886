var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('div',{staticClass:"mobile-width d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"bwsTitle mobile-top"},[_vm._v(" "+_vm._s(_vm.$app_title)+" ")])]),_c('div',{staticClass:"mobile-width"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"subTitle"},[_vm._v("Daftar Pos")]),_c('div',[(_vm.role === 'is_superuser')?_c('router-link',{staticClass:"btn btn-primary float-right my-2",staticStyle:{"padding":"5px 10px"},attrs:{"to":{ name: 'AddStation' },"type":"button"}},[_vm._v("Tambah Data")]):_vm._e()],1)]),_c('dataset',{attrs:{"ds-data":_vm.stations,"ds-sortby":_vm.sortBy,"ds-search-in":['station_name', 'balai']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ds = ref.ds;
return [_c('div',{attrs:{"data-page-count":ds.dsPagecount}},[_c('dataset-search',{attrs:{"ds-search-placeholder":"Search..."}})],1),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover table-responsive text-nowrap text-center table-borderless bg-white"},[_c('thead',{staticClass:"table-light"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_vm._l((_vm.cols),function(th,index){return _c('th',{key:th.field,staticClass:"thLight",class:['sort', th.sort],on:{"click":function($event){return _vm.click($event, index)}}},[_vm._v(" "+_vm._s(th.name)+" "),_c('i',{staticClass:"gg-select float-right"})])})],2)]),(_vm.loading_i)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('i',{staticClass:"zmdi zmdi-spinner zmdi-hc-spin",staticStyle:{"font-size":"1.5rem"}})]):_vm._e(),_c('dataset-item',{attrs:{"tag":"tbody"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [_c('tr',[_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(rowIndex + 1))]),_c('td',[_vm._v(_vm._s(row.station_name))]),(_vm.role == 'is_superuser')?_c('td',_vm._l((_vm.balais),function(item){return _c('span',{key:item.id},[(item.id == row.balai)?_c('span',[_vm._v(" "+_vm._s(item.balai)+" ")]):_vm._e()])}),0):_vm._e(),_c('td',[_vm._v(_vm._s(row.location))]),_c('td',[_vm._v(_vm._s(row.latitude))]),_c('td',[_vm._v(_vm._s(row.longitude))]),_c('td',[_c('router-link',{staticClass:"btn btn-primary btn-sm mx-1",attrs:{"type":"button","to":{
                          path: '/station/data/' + row.id + '/1',
                        }}},[_vm._v("Data")]),(_vm.role == 'is_staff' || _vm.role == 'is_superuser')?_c('span',[_c('router-link',{staticClass:"btn btn-success btn-sm mx-1",attrs:{"type":"button","to":("/station/update/" + _vm.balai + "/" + (row.id))}},[_vm._v("Edit")])],1):_vm._e()],1)])]}}],null,true)})],1)])])]),_c('div',{staticClass:"d-flex flex-md-row flex-column justify-content-between align-items-center"},[_c('dataset-info',{staticClass:"mb-2 mb-md-0"}),_c('dataset-pager')],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }