var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading_i)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",staticStyle:{"min-height":"35vh"}},[_c('i',{staticClass:"zmdi zmdi-spinner zmdi-hc-spin",staticStyle:{"font-size":"2rem","margin-right":"20px"}})]):_vm._e(),_c('span',{staticClass:"d-none"},[_vm._v(" "+_vm._s(_vm.stations)+" "),_c('hr'),_vm._v(" "+_vm._s(_vm.backupStat)+" ")]),(!_vm.loading_i)?_c('div',{staticClass:"box"},[_c('ul',{staticClass:"nav nav-tabs red",attrs:{"id":"tabs-tab","role":"tablist"}},[(_vm.total_awlr >= 1)?_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link d-flex flex-row py-1 px-2",class:{ active: _vm.total_awlr >= 1 },attrs:{"id":"tabs-AWLR-tab","data-bs-toggle":"pill","data-bs-target":"#tabs-AWLR","type":"button","role":"tab","aria-controls":"tabs-AWLR","aria-selected":"true"}},[_c('img',{staticClass:"rounded mx-auto d-inline imgSZ",attrs:{"src":_vm.awlr_i}}),_c('span',[_vm._v("AWLR")])])]):_vm._e(),(_vm.total_arr >= 1)?_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link d-flex flex-row py-1 px-2",class:{ active: _vm.total_arr >= 1 && _vm.total_awlr < 1 },attrs:{"id":"tabs-ARR-tab","data-bs-toggle":"pill","data-bs-target":"#tabs-ARR","type":"button","role":"tab","aria-controls":"tabs-ARR","aria-selected":"false"}},[_c('img',{staticClass:"rounded mx-auto d-inline imgSZ",attrs:{"src":_vm.arr_i}}),_c('span',[_vm._v("ARR")])])]):_vm._e(),(_vm.total_aws >= 1)?_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link d-flex flex-row py-1 px-2",class:{
            active: _vm.total_aws >= 1 && _vm.total_arr < 1 && _vm.total_awlr < 1,
          },attrs:{"id":"tabs-AWS-tab","data-bs-toggle":"pill","data-bs-target":"#tabs-AWS","type":"button","role":"tab","aria-controls":"tabs-AWS","aria-selected":"false"}},[_c('img',{staticClass:"rounded mx-auto d-inline imgSZ",attrs:{"src":_vm.aws_i}}),_c('span',[_vm._v("AWS")])])]):_vm._e()]),_c('div',{staticClass:"tab-content station-list",attrs:{"id":"tabs-tabContent"}},[(_vm.total_awlr >= 1)?_c('div',{staticClass:"tableFixHead tab-pane fade",class:{
          active: _vm.total_awlr >= 1,
          show: _vm.total_awlr >= 1,
          h100: _vm.ava_width <= 850,
        },attrs:{"id":"tabs-AWLR","role":"tabpanel","aria-labelledby":"tabs-AWLR-tab"}},[_c('table',{staticClass:"table table-hover table-responsive text-nowrap text-center table-border bg-white mx-2"},[_c('thead',{staticClass:"table-light"},[_c('tr',_vm._l((_vm.awlr_head),function(head,index){return _c('th',{key:index,class:{ thClass: index >= 0, sticky: index === 2 }},[_vm._v(" "+_vm._s(head)+" ")])}),0)]),_c('tbody',_vm._l((_vm.awlr_stations),function(station,index){return _c('tr',{key:station[0].id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[(station[1].table.siaga == [])?_c('span'):(station[1].table.siaga == 'MAINTENANCE')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.mtc_i}})]):(station[1].table.siaga == 'SIAGA 1')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.s1_i}})]):(station[1].table.siaga == 'SIAGA 2')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.s2_i}})]):(station[1].table.siaga == 'SIAGA 3')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.s3_i}})]):(station[1].table.siaga == 'OK')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.ok_i}})]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(station[0].station_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(station[0].sungai)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(station[1].table.date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatTime(station[1].table.date))+" ")]),_vm._l((_vm.conf_2(
                  station[1].table.weather_data,
                  station[1].table.array_act_symbol
                )),function(sensor,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(sensor.data)+" "+_vm._s(sensor.symbol)+" ")])}),(_vm.getYear(station[1].table.maint_date) >= 2020)?_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(station[1].table.maint_date))+" ")]):_c('td')],2)}),0)])]):_vm._e(),(_vm.total_arr >= 1)?_c('div',{staticClass:"tableFixHead tab-pane fade",class:{
          active: _vm.total_arr >= 1 && _vm.total_awlr < 1,
          show: _vm.total_arr >= 1 && _vm.total_awlr < 1,
          h100: _vm.ava_width <= 850,
        },attrs:{"id":"tabs-ARR","role":"tabpanel","aria-labelledby":"tabs-ARR-tab"}},[_c('table',{staticClass:"table table-hover table-responsive text-nowrap text-center table-border bg-white mx-2"},[_c('thead',{staticClass:"table-light"},[_c('tr',_vm._l((_vm.arr_head),function(head,index){return _c('th',{key:index,class:{ thClass: index >= 0, sticky: index === 2 }},[_vm._v(" "+_vm._s(head)+" ")])}),0)]),_c('tbody',_vm._l((_vm.arr_stations),function(station,index){return _c('tr',{key:station[0].id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[(station[1].table.siaga == [])?_c('span'):(station[1].table.siaga == 'MAINTENANCE')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.mtc_i}})]):(station[1].table.siaga == 'SIAGA 1')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.s1_i}})]):(station[1].table.siaga == 'SIAGA 2')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.s2_i}})]):(station[1].table.siaga == 'SIAGA 3')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.s3_i}})]):(station[1].table.siaga == 'OK')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.ok_i}})]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(station[0].station_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(station[1].table.date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatTime(station[1].table.date))+" ")]),_vm._l((_vm.conf_2(
                  station[1].table.weather_data,
                  station[1].table.array_act_symbol
                )),function(sensor,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(sensor.data)+" "+_vm._s(sensor.symbol)+" ")])}),(_vm.getYear(station[1].table.maint_date) >= 2020)?_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(station[1].table.maint_date))+" ")]):_c('td')],2)}),0)])]):_vm._e(),(_vm.total_aws >= 1)?_c('div',{staticClass:"tableFixHead tab-pane fade",class:{
          active: _vm.total_aws >= 1 && _vm.total_arr < 1 && _vm.total_awlr < 1,
          show: _vm.total_aws >= 1 && _vm.total_arr < 1 && _vm.total_awlr < 1,
          h100: _vm.ava_width <= 850,
        },attrs:{"id":"tabs-AWS","role":"tabpanel","aria-labelledby":"tabs-AWS-tab"}},[_c('table',{staticClass:"table table-hover table-responsive text-nowrap text-center table-border bg-white mx-2"},[_c('thead',{staticClass:"table-light"},[_c('tr',_vm._l((_vm.aws_head),function(head,index){return _c('th',{key:index,class:{ thClass: index >= 0, sticky: index === 2 }},[_vm._v(" "+_vm._s(head)+" ")])}),0)]),_c('tbody',_vm._l((_vm.aws_stations),function(station,index){return _c('tr',{key:station[0].id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[(station[1].table.siaga == [])?_c('span'):(station[1].table.siaga == 'MAINTENANCE')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.mtc_i}})]):(station[1].table.siaga == 'SIAGA 1')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.s1_i}})]):(station[1].table.siaga == 'SIAGA 2')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.s2_i}})]):(station[1].table.siaga == 'SIAGA 3')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.s3_i}})]):(station[1].table.siaga == 'OK')?_c('span',[_c('img',{staticClass:"statSZ",attrs:{"src":_vm.ok_i}})]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(station[0].station_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(station[1].table.date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatTime(station[1].table.date))+" ")]),_vm._l((_vm.conf_2(
                  station[1].table.weather_data,
                  station[1].table.array_act_symbol
                )),function(sensor,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(sensor.data)+" "+_vm._s(sensor.symbol)+" ")])}),(_vm.getYear(station[1].table.maint_date) >= 2020)?_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(station[1].table.maint_date))+" ")]):_c('td')],2)}),0)])]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }